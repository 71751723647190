import React, {Component} from 'react';

export default class TopBar extends Component {
    render(){
        return (
            <div id="topbar" class="d-flex align-items-center fixed-top">
                <div class="container d-flex justify-content-center justify-content-md-between">

                    <div class="contact-info d-flex align-items-center">
                        <i class="bi bi-phone d-flex align-items-center"><span>+33 09 50 17 18 60</span></i>
                        <i class="bi bi-clock d-flex align-items-center ms-4"><span> Ouvert de lundi à samedi de 11H30 à 14H30 et de 18H30 à 21H </span></i>
                    </div>

                    <div className="languages d-none d-md-flex align-items-center">
                        <ul>
                            <li><img src="assets/img/logo/logo-transblanc.gif" alt="" className="img-fluid"/></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
