import React, {Component} from 'react';

export default class WhyUs extends Component {
    render(){
        return (


            <section id="why-us" className="why-us about">
                <hr/><br/>
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2> POURQUOI NOUS</h2>
                        <p>Pourquoi choisir notre restaurant</p>
                    </div>


                    <div className="row">

                        <div className="col-lg-4">
                            <div className="box" data-aos="zoom-in" data-aos-delay="100">
                                <span>01</span>
                                <h4>L'originalité</h4>
                                <p>Sans aucun doute l'originalité de nos plats et leurs atouts nutritifs en font un
                                    ingrédient idéal pour vos repas </p>
                            </div>
                        </div>

                        <div className="col-lg-4 mt-4 mt-lg-0">
                            <div className="box" data-aos="zoom-in" data-aos-delay="200">
                                <span>02</span>
                                <h4>La référence qualité prix</h4>
                                <p>La qualité de notre service , carte oscillant entre plats classiques et cuisine
                                    plus tendance à des prix raisonnables </p>
                            </div>
                        </div>

                        <div className="col-lg-4 mt-4 mt-lg-0">
                            <div className="box" data-aos="zoom-in" data-aos-delay="300">
                                <span>03</span>
                                <h4>L'accessibilité</h4>
                                <p>Situé rez-de-chaussée du Centre commercial La Cascade de Laxou, notre restaurant
                                    est facilement accessibile à tous</p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        )
    }
}
