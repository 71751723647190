import React, {Component} from 'react';
import logo from '../logo.svg';

export default class Header extends Component {
    render(){
        return (

            <header id="header" className="fixed-top d-flex align-items-cente">
                <div className="container-fluid container-xl d-flex align-items-center justify-content-lg-between">

                    <h1 className="logo me-auto me-lg-0"><a href="index.html"> LIKALO Fast Food </a></h1>

                    <nav id="navbar" className="navbar order-last order-lg-0">
                        <ul>
                            <li><a className="nav-link scrollto active" href="#hero">Accueil</a></li>
                            <li><a className="nav-link scrollto" href="#events">Nos plats</a></li>
                            <li><a className="nav-link scrollto" href="#specials">Boissons</a></li>
                            <li><a className="nav-link scrollto" href="#chefs">Specialités</a></li>
                            <li><a className="nav-link scrollto" href="#gallery">Gallerie</a></li>
                            <li><a className="nav-link scrollto" href="#about">À propos</a></li>
                            <li><a className="nav-link scrollto" href="#contact">Contact</a></li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle"></i>
                    </nav>
                    <a href="#menu" className="book-a-table-btn scrollto d-none d-lg-flex">Menu</a>

                </div>
            </header>

        )
    }
}
