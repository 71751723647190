import React, {Component} from 'react';

export default class Gallery extends Component {
    render(){
        return (
            <section id="gallery" className="events gallerie">
                <hr/><br/>
                <div className="container" data-aos="fade-up">
                    <div className="section-title" >
                        <h2>Galerie</h2>
                        <p>Quelques photos de notre restaurant</p>
                    </div>
                </div>

                <div className="container-fluid" data-aos="fade-up" data-aos-delay="100">

                    <div className="row g-0">

                        <div className="col-lg-3 col-md-4">
                            <div className="gallery-item">
                                <a href="assets/img/gallery/gallery-1.webp" className="gallery-lightbox"
                                   data-gall="gallery-item">
                                    <img src="assets/img/gallery/gallery-1.webp" alt="" className="img-fluid" />
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4">
                            <div className="gallery-item">
                                <a href="assets/img/gallery/gallery-2.webp" className="gallery-lightbox"
                                   data-gall="gallery-item">
                                    <img src="assets/img/gallery/gallery-2.webp" alt="" className="img-fluid" />
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4">
                            <div className="gallery-item">
                                <a href="assets/img/gallery/gallery-3.webp" className="gallery-lightbox"
                                   data-gall="gallery-item">
                                    <img src="assets/img/gallery/gallery-3.webp" alt="" className="img-fluid" />
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4">
                            <div className="gallery-item">
                                <a href="assets/img/gallery/gallery-4.webp" className="gallery-lightbox"
                                   data-gall="gallery-item">
                                    <img src="assets/img/gallery/gallery-4.webp" alt="" className="img-fluid" />
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4">
                            <div className="gallery-item">
                                <a href="assets/img/gallery/gallery-5.webp" className="gallery-lightbox"
                                   data-gall="gallery-item">
                                    <img src="assets/img/gallery/gallery-5.webp" alt="" className="img-fluid" />
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4">
                            <div className="gallery-item">
                                <a href="assets/img/gallery/gallery-6.webp" className="gallery-lightbox"
                                   data-gall="gallery-item">
                                    <img src="assets/img/gallery/gallery-6.webp" alt="" className="img-fluid" />
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4">
                            <div className="gallery-item">
                                <a href="assets/img/gallery/gallery-7.webp" className="gallery-lightbox"
                                   data-gall="gallery-item">
                                    <img src="assets/img/gallery/gallery-7.webp" alt="" className="img-fluid" />
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4">
                            <div className="gallery-item">
                                <a href="assets/img/gallery/gallery-8.webp" className="gallery-lightbox"
                                   data-gall="gallery-item">
                                    <img src="assets/img/gallery/gallery-8.webp" alt="" className="img-fluid" />
                                </a>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

        )
    }
}
