import React, {Component} from 'react';

export default class Speciality extends Component {
    render(){
        return (


            <section id="chefs" className="chefs">
                <div className="container" data-aos="fade-up">
                    <hr/><br/>
                    <div className="section-title">
                        <h2>La cuisine du Chef</h2>
                        <p>Nos spécialités culinaires</p>
                    </div>

                    <div className="row">

                        <div className="col-lg-4 col-md-6">
                            <div className="member" data-aos="zoom-in" data-aos-delay="100">
                                <img src="assets/img/special/grill.webp" className="img-fluid" alt="les grillades au charbon" />
                                    <div className="member-info">
                                        <div className="member-info-content">
                                            <h4>Les Grillades</h4>
                                            <span>Master Chef saveur unique du grill</span>
                                        </div>
                                        <div className="social">
                                            <a href="+330745453530" className="twitter"><i
                                                className="bx bxl-whatsapp"></i></a>
                                            <a href="https://www.facebook.com/people/Likal%C3%B4-Label/100081461140484/"
                                               className="facebook"><i className="bx bxl-facebook"></i></a>
                                            <a href="https://www.instagram.com/p/CpFP1JcNkLB/?igshid=MDJmNzVkMjY="
                                               className="instagram"><i className="bx bxl-instagram"></i></a>
                                            <a href="https://www.google.com/maps/place/Likalo/@48.7003872,6.1333971,15z/data=!4m6!3m5!1s0x4794a3b528b5ca6d:0x9de96f5b10d6cd8d!8m2!3d48.7003872!4d6.1333971!16s%2Fg%2F11kb4r7480"
                                               className="google-plus"><i className="bx bxl-google"></i></a>
                                        </div>
                                    </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="member" data-aos="zoom-in" data-aos-delay="200">
                                <img src="assets/img/special/mamawich2.webp" className="img-fluid" alt="les sandwich" />
                                    <div className="member-info">
                                        <div className="member-info-content">
                                            <h4>Les Sandwich Mamawich</h4>
                                            <span>Sandwich composé d'un pain de mie garni de charcuteries et de frites et +</span>
                                        </div>
                                        <div className="social">
                                            <a href="+330745453530" className="twitter"><i
                                                className="bx bxl-whatsapp"></i></a>
                                            <a href="https://www.facebook.com/people/Likal%C3%B4-Label/100081461140484/"
                                               className="facebook"><i className="bx bxl-facebook"></i></a>
                                            <a href="https://www.instagram.com/p/CpFP1JcNkLB/?igshid=MDJmNzVkMjY="
                                               className="instagram"><i className="bx bxl-instagram"></i></a>
                                            <a href="https://www.google.com/maps/place/Likalo/@48.7003872,6.1333971,15z/data=!4m6!3m5!1s0x4794a3b528b5ca6d:0x9de96f5b10d6cd8d!8m2!3d48.7003872!4d6.1333971!16s%2Fg%2F11kb4r7480"
                                               className="google-plus"><i className="bx bxl-google"></i></a>
                                        </div>
                                    </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="member" data-aos="zoom-in" data-aos-delay="300">
                                <img src="assets/img/special/afro.webp" className="img-fluid" alt=" les plats africain" />
                                    <div className="member-info">
                                        <div className="member-info-content">
                                            <h4>Les Plats traditionnels Africain</h4>
                                            <span>La cuisine africaine est aussi riche que variée</span>
                                        </div>
                                        <div className="social">
                                            <a href="+330745453530" className="twitter"><i
                                                className="bx bxl-whatsapp"></i></a>
                                            <a href="https://www.facebook.com/people/Likal%C3%B4-Label/100081461140484/"
                                               className="facebook"><i className="bx bxl-facebook"></i></a>
                                            <a href="https://www.instagram.com/p/CpFP1JcNkLB/?igshid=MDJmNzVkMjY="
                                               className="instagram"><i className="bx bxl-instagram"></i></a>
                                            <a href="https://www.google.com/maps/place/Likalo/@48.7003872,6.1333971,15z/data=!4m6!3m5!1s0x4794a3b528b5ca6d:0x9de96f5b10d6cd8d!8m2!3d48.7003872!4d6.1333971!16s%2Fg%2F11kb4r7480"
                                               className="google-plus"><i className="bx bxl-google"></i></a>
                                        </div>
                                    </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>


    )
    }
}
