import React, {Component} from 'react';

export default class Home extends Component {
    render(){
        return (

            <section id="hero" class="d-flex align-items-center">
                <div class="container position-relative text-center text-lg-start" data-aos="zoom-in" data-aos-delay="100">
                    <div class="row">
                        <div class="col-lg-8">
                            <h1>Bienvenue au <span>Restaurant Fast Food  <img src="assets/img/logo/logo-text3.gif" alt="likalo-text" class="img-fluid" /></span></h1>
                            <h2>Goûtez de la bonne nourriture africaine!</h2>

                            <div class="btns">
                                <a href="#events" class="btn-menu animated fadeInUp scrollto">Plat du jour</a>
                                <a href="#menu" class="btn-book animated fadeInUp scrollto">Notre Menu</a>
                            </div>
                        </div>
                        <div class="col-lg-4 d-flex align-items-center justify-content-center position-relative" data-aos="zoom-in" data-aos-delay="200">
                            <a href="https://www.youtube.com/watch?v=BbTSv4sykcg" class="glightbox play-btn"></a>
                        </div>


                    </div>
                </div>
            </section>


        )
    }
}
