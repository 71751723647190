import React, {Component} from 'react';

export default class Drink extends Component {
    render(){
        return (


            <section id="specials" className="specials">
                <hr/><br/>
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>Jus Likalo</h2>
                        <p>Découvrez nos boissons natureles</p>
                    </div>

                    <div className="row row-data" data-aos="fade-up" data-aos-delay="100">
                        <div className="col-lg-3">
                            <ul className="nav nav-tabs flex-column">
                                <li className="nav-item">
                                    <a className="nav-link active show" data-bs-toggle="tab" href="#tab-1">Jus de
                                        Baobab</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-bs-toggle="tab" href="#tab-2">Jus de Bissap</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-bs-toggle="tab" href="#tab-3">Jus de Gingembre </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-bs-toggle="tab" href="#tab-4">Jus de Tamarin</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-bs-toggle="tab" href="#tab-5">Thés naturels</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-9 mt-4 mt-lg-0">
                            <div className="tab-content">
                                <div className="tab-pane active show" id="tab-1">
                                    <div className="row">
                                        <div className="col-lg-8 details details-special  order-2 order-lg-1">
                                            <h3>Poudre de Fruit de Baobab , eau , sucre</h3>
                                            <p className="fst-italic">Le pain de singe est le fruit comestible du
                                                baobab africain au goût acidulé</p>
                                            <p>
                                                Boisson aux nombreuses vertus:<br/>
                                                Trois fois plus riche en calcium que le lait de vache,
                                                Quatre fois plus riche en vitamine C que l’orange
                                                Six fois plus riche en potassium que la banane
                                                Neuf fois plus riche fibre qu’un pruneau séché
                                                Aussi riche en fer que la viande rouge et en magnésium que le
                                                chocolat

                                            </p>
                                        </div>
                                        <div className="col-lg-4 text-center order-1 order-lg-2">
                                            <img src="assets/img/jus/01.webp" className="img-fluid" alt="jus de baobab" />
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="tab-2">
                                    <div className="row">
                                        <div className="col-lg-8 details details-special  order-2 order-lg-1">
                                            <h3>Fleur d’hibiscus séchées , eau , sucre</h3>
                                            <p className="fst-italic">L'hibiscus est une fleur qui a énormément de
                                                bienfaits pour la santé</p>
                                            <p>
                                                Boisson aux nombreuses vertus: <br/>
                                                Excellent pour la digestion
                                                Boisson énergétique, un concentré de vitamines (B2, B3 , C...)
                                                Combat l'hypertension
                                                Propriété antalgique, Anti inflammatoire
                                                Combat l'anémie
                                            </p>
                                        </div>
                                        <div className="col-lg-4 text-center order-1 order-lg-2">
                                            <img src="assets/img/jus/02.webp" className="img-fluid" alt="img-jus-de-bissap" />
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="tab-3">
                                    <div className="row">
                                        <div className="col-lg-8 details  details-special order-2 order-lg-1">
                                            <h3>Gingimbre, eau , sucre </h3>
                                            <p className="fst-italic">Le gingembre est un rhizome appartenant à la
                                                même famille que le curcuma. Tout comme ce dernier, il est
                                                aujourd'hui considéré comme un « super aliment » </p>
                                            <p>
                                                Boisson aux nombreuses vertus: <br/>
                                                Anti-inflammatoire et antidouleur naturel
                                                Détoxifiant qui assure une purification naturelle du corps.
                                                Stabilise le taux de sucre dans le sang
                                                Réduit les nausées (matinales) et améliore la digestion ?
                                                Stimulant et réchauffant
                                                la plante aurait même des vertus aphrodisiaques
                                            </p>
                                        </div>
                                        <div className="col-lg-4 text-center order-1 order-lg-2">
                                            <img src="assets/img/jus/03.webp" className="img-fluid" alt="img-jus-de-ginger" />
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="tab-4">
                                    <div className="row">
                                        <div className="col-lg-8 details details-special   order-2 order-lg-1">
                                            <h3>Pulpe de Tamarin , eau , sucre</h3>
                                            <p className="fst-italic">Le tamarin est un type de fruit tropical qui
                                                provient du tamarinier, originaire d'Afrique idéal pour faire le
                                                plein de vitamines</p>
                                            <p>
                                                Boisson aux nombreuses vertus:<br/>
                                                Lutte contre les troubles intestinaux
                                                Possède des propriétés antiseptiques
                                                Soulage les maux de gorge et des problèmes respiratoires
                                                Recommandé aux personnes sujettes à de l’hypertension
                                                Stimule le système hépatique et recommandé aux diabétiques
                                                Atténue les nausées chez la femme enceinte
                                            </p>
                                        </div>
                                        <div className="col-lg-4 text-center order-1 order-lg-2">
                                            <img src="assets/img/jus/04.webp" className="img-fluid" alt="img-jus-de-tamarin" />
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="tab-5">
                                    <div className="row">
                                        <div className="col-lg-8 details details-special   order-2 order-lg-1">
                                            <h3>Moringa , Kinkeliba , Citronelle, Ataya</h3>
                                            <p className="fst-italic">
                                                <b><u>Le thé au Moringa</u></b><br/>
                                                Le thé au Moringa
                                                Booste l’énergie sans caféine, riche en vitamine C D E...
                                                chargée en antioxydants qui aide à se prémunir des effets du
                                                vieillissement.
                                                contient beaucoup de fer et beaucoup d’acide folique
                                                Renforce le système immunitaire et aide aussi à la perte de poids
                                                Ne jaunit pas les dents… et ça c’est un bonus !

                                            </p>
                                            <p>
                                                <b><u>Le thé au Kinkeliba</u></b><br/>
                                                Très bon Digestif et soulage aussi les nausées ;
                                                Favorise l’excrétion de la bile et protège les cellules du foie ;
                                                Antidiabète : le kinkéliba régule le taux de sucre dans le sang ;
                                                Dépuratif, drainant et diurétique : il draine les toxines de
                                                l’organisme
                                                Anti-inflammatoire et antibactérien ;
                                                Adjuvant aux régimes amaigrissants
                                                Antipaludéen et Fébrifuge : il est connu en Afrique pour faire
                                                baisser la fièvre et lutter contre le paludisme.


                                            </p>
                                        </div>
                                        <div className="col-lg-4 text-center order-1 order-lg-2">
                                            <img src="assets/img/jus/specials-5.webp" className="img-fluid" alt="img-jus-the-africain" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>


    )
    }
}
