import React, {Component} from 'react';

export default class Avis extends Component {
    render(){
        return (


            <section id="testimonials" className="testimonials section-bg">
                <div className="container" data-aos="fade-up">
                    <hr/><br/>
                    <div className="section-title">
                        <h2>Témoignages</h2>
                        <p>Ce qu’ils disent de nous</p>
                    </div>

                    <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="testimonial-item">
                                    <p>
                                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                        Pour du bon fait maison avec un très bon rapport qualité-prix, foncez ! Le
                                        mafé est un régal, n'hésitez pas à donner de la force à cette petite
                                        enseigne qui viens d'ouvrir et qui a du potentiel 😁👌
                                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                    </p>
                                    <img
                                        src="https://lh3.googleusercontent.com/a-/ACB-R5TMY0ygMRkrIm_jwlhlFLxM2ksMPw9CbYybdcC8_g=w60-h60-p-rp-mo-br100"
                                        className="testimonial-img" alt="avis google restaurant likalo" />
                                        <h3>Maokrush</h3>
                                        <h4>il y a une semaine</h4>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div className="testimonial-item">
                                    <p>
                                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>

                                        Petit enseigne et accueil vraiment sympa
                                        Et pour le prix vraiment raisonnable, c'était super bon
                                        Mafé poulet, sauce à base de beurre de cacahuète
                                        Je conseille !
                                        À bientôt soyez en sûr 😁
                                        Visité en février
                                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                    </p>
                                    <img
                                        src="https://lh3.googleusercontent.com/a-/ACB-R5SXovGOj-F4ldJskMUwU1tHvNSJiFYKUy36YowNWA=w60-h60-p-rp-mo-ba4-br100"
                                        className="testimonial-img" alt="avis client restaurant likalo" />
                                        <h3>Sha Dow</h3>
                                        <h4>Repas sur place | Dîner</h4>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div className="testimonial-item">
                                    <p>
                                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                        Excellente soirée<br/>
                                        Nous sommes venus de loin pour découvrir le restaurant qui nous a fait
                                        partagé d’excellents plats avec de merveilleuses saveurs.
                                        Navons passé un moment très agréable entre amis. Et nous ne manquerons pas
                                        d’y retourner.
                                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                    </p>
                                    <img
                                        src="https://lh3.googleusercontent.com/a-/ACB-R5QJB5rT-vsWDnty90qUSLOLTfvbFYHv3RbiQRoYViM=w60-h60-p-rp-mo-ba2-br100"
                                        className="testimonial-img" alt="avis google fast-food likalo" />
                                        <h3>Nathalie Lama</h3>
                                        <h4>il y a une semaine</h4>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div className="testimonial-item">
                                    <p>
                                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                        Endroit sympa.<br/>
                                        J'y suis allée car une amie organisait qui m'a invité pour découvrir.
                                        L'ambiance était très bien.
                                        J'y referai un tour pour tester leur menu.
                                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                    </p>
                                    <img
                                        src="https://lh3.googleusercontent.com/a-/ACB-R5QzVqMYzlR3EnCbL1NlJ9FyB8HarYcljL4LG74QZBs=w60-h60-p-rp-mo-ba4-br100"
                                        className="testimonial-img" alt="google testimonial restaurant likalo" />
                                        <h3>Annie Kolemba </h3>
                                        <h4>il y a deux semaine</h4>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div className="testimonial-item">
                                    <p>
                                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>

                                        Un régal !
                                        Excellent restaurant.
                                        Les plats sont copieux pour un bon rapport qualité prix.
                                        L'accueil est chaleureux et convivial.
                                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                    </p>
                                    <img
                                        src="https://lh3.googleusercontent.com/a/AGNmyxaZUOqf6LH4Ze0BlTymxuaHU8_ptUcaPo7xB--D=w60-h60-p-rp-mo-br100"
                                        className="testimonial-img" alt="google testimonial fast-food likalo" />
                                        <h3>Badr Bendahou</h3>
                                        <h4> Visité en février</h4>
                                </div>
                            </div>

                        </div>
                        <div className="swiper-pagination"></div>
                    </div>

                </div>
            </section>




    )
    }
}
