import React, {Component} from 'react';

export default class About extends Component {
    render(){
        return (
            <section id="about" className="about">
                <hr/><br/>
                <div className="container" data-aos="fade-up">

                    <div className="row">
                        <div className="col-lg-6 order-1 order-lg-2" data-aos="zoom-in" data-aos-delay="100">
                            <div className="about-img">
                                <img src="assets/img/bg/about.webp" alt="A promo du restaurant likalo" />
                            </div>
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                            <h3>Likalo fast-food est un produit de la marque déposée LIKALO.</h3>
                            <p className="fst-italic">
                                <span className="visibilityhidden">********</span>Nous sommes un jeun label spécialisé
                                dans la promotion de la culture Africaine. Nous avons un restaurant fast-food
                                africain à laxou.
                                <br/>Nous nous intéressons principalement à 3 secteurs culturels:
                            </p>
                            <ul>
                                <li><i className="bi bi-check-circle"></i> Art Africain.</li>
                                <li><i className="bi bi-check-circle"></i> Gastronomie Africaine.</li>
                                <li><i className="bi bi-check-circle"></i> Mode Africaine.</li>
                            </ul>

                            <h5>Mot de la fondatrice.</h5>
                            <p>
                                <span className="visibilityhidden">********</span>Nous sommes par la gastronomie et
                                nous souhaitons mettre au service de nos clients le meilleur de la richesse
                                culinaire Africaine. Nous savons qu’il nous faut d’avantages d’expérience et de
                                compétences pour vous satisfaire, mais nous mettrons toutes les chances de notre
                                côté pour répondre à vos besoins. Et nous serions plus qu'honoré de vous recevoir
                                très prochainement dans notre restaurant.
                            </p>
                        </div>
                    </div>

                </div>
            </section>


    )
    }
}
