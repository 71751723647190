import React, {Component} from 'react';

export default class Footer extends Component {
    render(){
        return (
            <footer id="footer">
                <div class="footer-top">
                    <div class="container">
                        <div class="row">

                            <div class="col-lg-3 col-md-6">
                                <div class="footer-info">
                                    <h3>Restaurantly</h3>
                                    <p>18 rue de la mortagne  <br/>
                                        54520 Laxou<br/><br/>
                                        <strong>Phone:</strong> +33 09 50 17 18 60<br/>
                                        <strong>Email:</strong> likalolabel@gmail.com<br/>
                                    </p>
                                    <div class="social-links mt-3">
                                        <a href="+330745453530" class="twitter"><i class="bx bxl-whatsapp"></i></a>
                                        <a href="https://www.facebook.com/people/Likal%C3%B4-Label/100081461140484/" class="facebook"><i class="bx bxl-facebook"></i></a>
                                        <a href="https://www.instagram.com/p/CpFP1JcNkLB/?igshid=MDJmNzVkMjY=" class="instagram"><i class="bx bxl-instagram"></i></a>
                                        <a href="https://www.google.com/maps/place/Likalo/@48.7003872,6.1333971,15z/data=!4m6!3m5!1s0x4794a3b528b5ca6d:0x9de96f5b10d6cd8d!8m2!3d48.7003872!4d6.1333971!16s%2Fg%2F11kb4r7480" class="google-plus"><i class="bx bxl-google"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-6 footer-links">
                                <h4>Liens utils</h4>
                                <ul>
                                    <li><a class="bx bx-chevron-right" href="#about">À propos</a></li>
                                    <li><a class="bx bx-chevron-right" href="#events">Plat du jour</a></li>
                                    <li><a class="bx bx-chevron-right" href="#menu">Menu</a></li>
                                    <li><a class="bx bx-chevron-right" href="#why-us">Pourquoi-nous</a></li>
                                    <li><a class="bx bx-chevron-right" href="#gallery">Gallerie</a></li>
                                </ul>
                            </div>

                            <div class="col-lg-3 col-md-6 footer-links">
                                <h4>Nos Services</h4>
                                <a href="#menu">
                                    <ul>
                                        <li data-filter=".filter-boissons"><i class="bx bx-chevron-right"></i>Boissons</li>
                                        <li data-filter=".filter-desserts"><i class="bx bx-chevron-right"></i>Desserts</li>
                                        <li data-filter=".filter-grillades"><i class="bx bx-chevron-right"></i>Grillades</li>
                                        <li data-filter=".filter-salads"><i class="bx bx-chevron-right"></i>Salades</li>
                                        <li data-filter=".filter-sanwichs"><i class="bx bx-chevron-right"></i>Sandwichs</li>
                                        <li data-filter=".filter-specialty"><i class="bx bx-chevron-right"></i>Spécialités du jour</li>

                                    </ul>
                                </a>
                            </div>

                            <div class="col-lg-4 col-md-6 footer-newsletter">
                                <h4>Notre Newsletter</h4>
                                <p>Recevez des notifications sur nos nouvelles offres et services</p>
                                <form action="" method="post">
                                    <input type="email" name="email" /><input type="submit" value="Souscrire" />
                                </form>

                            </div>

                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="copyright">
                        &copy; Copyright <strong><span>Fops</span></strong>. All Rights Reserved
                    </div>
                    <div class="credits">
                        Designed by <a href="https://kayefaye-fa3ec.web.app">Yem Mback Pierre</a>
                    </div>
                </div>
            </footer>

        )
    }
}
