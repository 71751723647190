import React, {Component} from 'react';

export default class Menu extends Component {
    render(){
        return (

            <section id="menu" className="menu section-bg">
                <div className="container" data-aos="fade-up">

                    <div className="section-title" id="menu-section-title">
                        <h2>Menu</h2>
                        <p className="sub-title">Consultez notre menu savoureux</p>
                    </div>

                    <div className="row" data-aos="fade-up" data-aos-delay="100">
                        <div className="col-lg-12 d-flex justify-content-center">
                            <ul id="menu-flters">
                                <li data-filter="*" className="filter-active">Tous</li>
                                <li data-filter=".filter-boissons">Boissons</li>
                                <li data-filter=".filter-desserts">Desserts</li>
                                <li data-filter=".filter-grillades">Grillades</li>
                                <li data-filter=".filter-salads">Salades</li>
                                <li data-filter=".filter-sanwichs">Sandwichs</li>
                                <li data-filter=".filter-specialty">Spécialités du jour</li>
                            </ul>
                        </div>
                    </div>


                    <div className="row menu-container" data-aos="fade-up" data-aos-delay="200">
                        <hr/>
                        <div className="row clearfix jus ">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 menu-item filter-boissons">

                                <div className="menu-content">
                                    <a href="#">NOS BOISSONS</a><span></span>
                                </div>
                                <div className="menu-ingredients">
                                    La société de fabrication des boissons naturelles mais vous offrons aussi des
                                    boissons standards
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-boissons">
                                <img src="assets/img/menu/water.webp" className="menu-img" alt="water"/>
                                <div className="menu-content">
                                    <a href="#">EAU MINÉRALE</a><span>1€</span>
                                </div>
                                <div className="menu-ingredients">
                                    Mélange de jus d’orange, d’huile essentielle d’orange, de pulpe et de fines bulles
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-boissons">
                                <img src="assets/img/menu/jus.webp" className="menu-img" alt="Boissons Standards"/>
                                <div className="menu-content">
                                    <a href="#">BOISSONS STANDARD</a><span>1,50€</span>
                                </div>
                                <div className="menu-ingredients">
                                    Coca , fanta , oasis , sprite etc
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-boissons">
                                <img src="assets/img/menu/likalo25.webp" className="menu-img" alt="Jus Likalo 250 ml"/>
                                <div className="menu-content">
                                    <a href="#">JUS NATUREL LIKALO 25cl</a><span>3€</span>
                                </div>
                                <div className="menu-ingredients">
                                    Jus de , Baobab ,Bissap, Gingimbre , Tamarin etc
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-boissons">
                                <img src="assets/img/menu/orangina.webp" className="menu-img" alt="orangina"/>
                                <div className="menu-content">
                                    <a href="#">ORANGINA</a><span>2€</span>
                                </div>
                                <div className="menu-ingredients">
                                    Eau plate ou gazeuse
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-boissons">
                                <img src="assets/img/menu/cafe.webp" className="menu-img" alt="cafe"/>
                                <div className="menu-content">
                                    <a href="#">CAFÉ</a><span>1,50€</span>
                                </div>
                                <div className="menu-ingredients">
                                    café , eau , sucre, (lait)
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-boissons">
                                <img src="assets/img/menu/the.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">THÉ STANDARD</a><span>1,50€</span>
                                </div>
                                <div className="menu-ingredients">
                                    Thés Africains ou Thés Standards
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-boissons">
                                <img src="assets/img/menu/theafricain.webp" className="menu-img" alt="Thé d'Afrique"/>
                                <div className="menu-content">
                                    <a href="#">THÉ AFRICAIN</a><span>1,50€</span>
                                </div>
                                <div className="menu-ingredients">
                                    Thés Africains ou Thés Standards
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-boissons">
                                <img src="assets/img/menu/petitebiere.webp" className="menu-img" alt="Petite Bière"/>
                                <div className="menu-content">
                                    <a href="#">PETITE BIÈRE</a><span>4€</span>
                                </div>
                                <div className="menu-ingredients">
                                    Kronenbourg, 1664, Heineken, Pelforth, Grimbergen, Desperados...
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-boissons">
                                <img src="assets/img/menu/grandebiere.webp" className="menu-img" alt="Grande Bière"/>
                                <div className="menu-content">
                                    <a href="#">GRANDE BIÈRE</a><span>7€</span>
                                </div>
                                <div className="menu-ingredients">
                                    simcoe, citra, 86, bourgogne, Grimbergen, elixkir...
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-boissons">
                                <img src="assets/img/menu/vin.webp" className="menu-img" alt="Grande Bière"/>
                                <div className="menu-content">
                                    <a href="#">VIN</a><span>10€</span>
                                </div>
                                <div className="menu-ingredients">
                                    Vin blanc, Vin rouge, Vin rosé, Vin mousseux...
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-boissons">
                                <img src="assets/img/menu/verrebiere.webp" className="menu-img"
                                     alt="Un verre de bière"/>
                                <div className="menu-content">
                                    <a href="#">VERRE DE BIÈRE</a><span>3,50€</span>
                                </div>
                                <div className="menu-ingredients">
                                    Un verre de bière
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-boissons">
                                <img src="assets/img/menu/verrevin.webp" className="menu-img" alt="Un verre de vin "/>
                                <div className="menu-content">
                                    <a href="#">VERRE DE VIN</a><span>3,50€</span>
                                </div>
                                <div className="menu-ingredients">
                                    Un verre de vin
                                </div>
                            </div>
                        </div>
                        <div className="row clearfix desert">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 menu-item filter-desserts">

                                <div className="menu-content">
                                    <a href="#">NOS DESSERTS</a><span></span>
                                </div>
                                <div className="menu-ingredients">
                                    Les desserts d'Europe et les desserts d'Afrique
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-desserts">
                                <img src="assets/img/menu/classique.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">DESSERT CLASSIQUE</a><span>2€</span>
                                </div>
                                <div className="menu-ingredients">
                                    Éclaire/Cœur C. au chocolat, Opéra café de Colombie, Mini Tarte Tatin, Nougat
                                    glacée
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-desserts">
                                <img src="assets/img/menu/exotique.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">DESSERTS EXOTIQUE</a><span>3,00 €</span>
                                </div>
                                <div className="menu-ingredients">
                                    Dessert des îles, dessert africain
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-desserts">
                                <img src="assets/img/menu/tarte.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">TARTE</a><span>2,50 €</span>
                                </div>
                                <div className="menu-ingredients">
                                    Tarte aux fruits de saison, Tarte de Manioc
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-desserts">
                                <img src="assets/img/menu/salade-fruit.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">Salade de fruit</a><span>2,50 €</span>
                                </div>
                                <div className="menu-ingredients">
                                    Salade aux fruits de saison
                                </div>
                            </div>
                        </div>

                        <div className="row clearfix braise ">

                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 menu-item filter-grillades">

                                <div className="menu-content">
                                    <a href="#">NOS BARBECUES</a><span></span>
                                </div>
                                <div className="menu-ingredients">
                                    Goûtez nos grillades au charbon de bois naturel
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-grillades">
                                <img src="assets/img/menu/dibidagneau.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">BOEUF BRAISÉ </a><span>15.00€</span>
                                </div>
                                <div className="menu-ingredients">
                                    Viande de boeuf, piment , moutarde , mayo ,complément
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-grillades">
                                <img src="assets/img/menu/poisson.webp" className="menu-img" alt="Poisson"/>
                                <div className="menu-content">
                                    <a href="#">POISSON BRAISÉ </a><span>a partir de 12.00€</span>
                                </div>
                                <div className="menu-ingredients">
                                    Poisson, piment , moutarde , mayo ,complément
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-grillades">
                                <img src="assets/img/menu/demipouletbraise.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">DEMI POULET BRAISÉ</a><span>15.00€</span>
                                </div>
                                <div className="menu-ingredients">
                                    Ailes ou cuisse de poulet, piment , moutarde , mayo ,complément
                                </div>
                            </div>

                        </div>
                        <div className="row clearfix mamwich ">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  menu-item filter-sanwichs">

                                <div className="menu-content">
                                    <a href="#">SANDWICHS MAMAWICHS CHAUDS</a><span></span>
                                </div>
                                <div className="menu-ingredients">
                                    Composez vos sandwichs sur place
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-sanwichs">
                                <img src="assets/img/menu/megg.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">SANDWICHS MAMAWICHS BIG EGG</a><span>5,00 €</span>
                                </div>
                                <div className="menu-ingredients">
                                    Pain , tomate , carottes , omelette , salade vert , sauce
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-sanwichs">
                                <img src="assets/img/menu/mbeans.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">SANDWICHS MAMAWICHS BEANS </a><span>5 €</span>
                                </div>
                                <div className="menu-ingredients">
                                    Pain ,haricot épicés avec viande hachée
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-sanwichs">
                                <img src="assets/img/menu/mmeat.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">SANDWICHS MAMAWICHS EXTRA MEAT</a><span>6,00 €</span>
                                </div>
                                <div className="menu-ingredients">
                                    Pain, Escalope de dinde ou viande boeuf, saucisson halal , legume , fritte
                                    ,sause maison
                                </div>
                            </div>
                        </div>

                        <div className="row clearfix salade ">

                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  menu-item filter-salads">

                                <div className="menu-content">
                                    <a href="#">LES SALADES</a><span></span>
                                </div>
                                <div className="menu-ingredients">
                                    Salades divers
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-salads">
                                <img src="assets/img/menu/sconcombre.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">SALADE DE CONCOMBRES</a><span>4,00 €</span>
                                </div>
                                <div className="menu-ingredients">
                                    Concombre , Vinaigrette, huile,Sel, poivre, Persil
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-salads">
                                <img src="assets/img/menu/shord.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">SALADE COMPOSÉE HORS D'OEUVRE</a><span>4,00 €</span>
                                </div>
                                <div className="menu-ingredients">
                                    choux, tomates, carotte, concombre, oignon, oeuf, laitue
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  menu-item filter-salads">
                                <img src="assets/img/menu/salade-fruit.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">SALADE DE TOMATES</a><span>3,00 €</span>
                                </div>
                                <div className="menu-ingredients">
                                    Tomates, oignon, huile d'olive, vinaigre blanc, brins de coriandre, sel, poivre
                                </div>
                            </div>


                        </div>

                        <div className="row clearfix platjrs ">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  menu-item filter-specialty">

                                <div className="menu-content">
                                    <a href="#">LE PLAT DU JOUR </a><span></span>
                                </div>
                                <div className="menu-ingredients nb">
                                    <b><u>NB:</u></b> <span className="visibilityhidden">***</span> La disponibilité de
                                    l’un de ces plats dépend essentiellement du plat du jour proposé afin de mettre en
                                    valeur les spécialités africaines.

                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 menu-item filter-specialty">
                                <img src="assets/img/menu/choux.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">CHOUX SAUTÉ</a><span>12,00 €</span>
                                </div>
                                <div className="menu-ingredients">
                                    Choux émincé, tomates, oignon émincé, gousse d'ail émincée, viande au choix,
                                    d'huile d'olive, légumes , épices et arômes d'Afrique
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 menu-item filter-specialty">
                                <img src="assets/img/menu/colombo.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">CURRY JAMAICAIN</a><span>12,00 €</span>
                                </div>
                                <div className="menu-ingredients">
                                    Viande de poulet ou bœuf, pommes de terre, Curry poudre, légumes , épices , huile,
                                    Assaisonnement exotique
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 menu-item filter-specialty">
                                <img src="assets/img/menu/emince.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">ÉMINCÉ DE VIANDE AU CURRY</a><span>12,00 €</span>
                                </div>
                                <div className="menu-ingredients">
                                    Escalopes de dinde ou viande de bœuf, huile d’olive, épices et arômes, huile,
                                    crème fraîche liquide
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 menu-item filter-specialty">
                                <img src="assets/img/menu/couscous.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">COUSCOUS DU MAGHREB + SAUCE VIANDE</a><span>12,00 €</span>
                                </div>
                                <div className="menu-ingredients">
                                    Couscous magrébin, Viande de poulet ou bœuf, pommes de terre, légumes , épices ,
                                    huile, sauce maison
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 menu-item filter-specialty">
                                <img src="assets/img/menu/gombo.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">SAUCE GOMBO SEMOULE OU MANIOC</a><span>12,00 €</span>
                                </div>
                                <div className="menu-ingredients">
                                    Gombo, viande, crevette, ou poisson fumée , huile, arachide, légumes , épices et
                                    arômes d'Afrique
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 menu-item filter-specialty">
                                <img src="assets/img/menu/thiep.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">THIEP</a><span>12,00 €</span>
                                </div>
                                <div className="menu-ingredients">
                                    Au poulet ou bœuf ou poisson
                                    Riz parfumé, viande de bœuf ou poulet
                                    tubercules, carottes
                                    légumes, épices , huile, sel et arômes
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 menu-item filter-specialty">
                                <img src="assets/img/menu/mafe.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">MAFÉ-RIZ</a><span>12,00 €</span>
                                </div>
                                <div className="menu-ingredients">
                                    Viande de (bœuf ou poulet)
                                    patates douces, carottes
                                    tomates, oignons, ail , épices ,d'huile, sel et arômes
                                    pâte d’arachide
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 menu-item filter-specialty">
                                <img src="assets/img/menu/pdg.webp" className="menu-img" alt=""/>
                                <div className="menu-content">
                                    <a href="#">POULET DG</a><span>12,00 €</span>
                                </div>
                                <div className="menu-ingredients">
                                    Viande de poulet , Aloko, pommes de terre , huile ,tomates, carottes , haricot
                                    vert, autre légumes, épices et arômes d'Afrique
                                </div>
                            </div>


                        </div>
                        <hr/>
                    </div>
                </div>
            </section>

        )
    }
}
