import React, {Component} from 'react';

export default class Contact extends Component {
    render(){
        return (
            <section id="contact" className="contact">
                <hr/><br/>
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>Contact</h2>
                        <p>Nous Contacter</p>
                    </div>
                </div>

                <div data-aos="fade-up">
                    <h4>Géolocalisation sur carte Google Map</h4>
                    <iframe className="geoloc"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10533.065960013631!2d6.124642369775391!3d48.7003872!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4794a3b528b5ca6d%3A0x9de96f5b10d6cd8d!2sLikalo!5e0!3m2!1sfr!2ssn!4v1677316723817!5m2!1sfr!2ssn"
                            frameBorder="0" allowFullScreen></iframe>
                </div>

                <div className="container" data-aos="fade-up">

                    <div className="row mt-5">

                        <div className="col-lg-4">
                            <div className="info">
                                <div className="address">
                                    <i className="bi bi-geo-alt"></i>
                                    <h4>Adresse:</h4>
                                    <p>18 rue de la mortagne 54520 Laxou</p>
                                </div>

                                <div className="open-hours">
                                    <i className="bi bi-clock"></i>
                                    <h4>Heures d’ouverture:</h4>
                                    <p>
                                        Lundi-Samedi:<br/>
                                        11H30-14H30 et 18H30-21H
                                    </p>
                                </div>

                                <div className="email">
                                    <i className="bi bi-envelope"></i>
                                    <h4>Email:</h4>
                                    <p>likalolabel@gmail.com</p>
                                </div>

                                <div className="phone">
                                    <i className="bi bi-phone"></i>
                                    <h4>Tel:</h4>
                                    <p>+33 09 50 17 18 60</p>
                                </div>

                            </div>

                        </div>

                        <div className="col-lg-8 mt-5 mt-lg-0">

                            <form action="http://localhost/senemailler/contact.php" method="post" role="form"
                                  className="php-email-form">
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <input type="text" name="name" className="form-control" id="nom"
                                               placeholder="Votre Nom" required />
                                    </div>
                                    <div className="col-md-6 form-group mt-3 mt-md-0">
                                        <input type="email" className="form-control" name="email" id="mail"
                                               placeholder="Votre Email" required />
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    <input type="text" className="form-control" name="subject" id="subject"
                                           placeholder="Objet" required />
                                </div>
                                <div className="form-group mt-3">
                                        <textarea className="form-control" name="message" rows="8" placeholder="Message"
                                                  required></textarea>
                                </div>
                                <div className="my-3">
                                    <div className="loading">Loading</div>
                                    <div className="error-message"></div>
                                    <div className="sent-message">Votre message a été envoyé. Merci!</div>
                                </div>
                                <div className="text-center">
                                    <button type="submit">Envoyer le message</button>
                                </div>
                            </form>

                        </div>

                    </div>

                </div>
            </section>
        )
    }
}
