import './App.css';
import Header from './layout/header';
import TopBar from "./layout/top-bar";
import Footer from "./layout/footer";
import Home from "./sections-static/home";
import Platjr from "./sections-dynamic/platjr";
import Drink from "./sections-dynamic/drink";
import Speciality from "./sections-static/speciality";
import About from "./sections-static/about";
import Menu from "./sections-dynamic/menu";
import Gallery from "./sections-dynamic/gallery";
import WhyUs from "./sections-static/why-us";
import Avis from "./sections-static/avis";
import Contact from "./sections-dynamic/contact";
function App() {
  return (
    <div className="App">
        <Platjr />
        <Menu />
        <Drink />
        <Gallery />
    </div>
  );
}

export default App;
