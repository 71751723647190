import React, {Component} from 'react';

export default class Platjr extends Component {
    render(){
        return (


            <section id="events" className="platjour">
                <hr/>
                <br/>

                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>Plat du jour</h2>
                        <p>Découvrez nos spécialités d'aujourd'hui</p>
                    </div>

                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="swiper-slide carousel-item active">
                                <div className="row event-item">
                                    <div className="col-lg-6">
                                        <img src="assets/img/platj/mafe.webp" className="img-fluid" alt=""/>
                                    </div>
                                    <div className="col-lg-6 pt-4 pt-lg-0 content">
                                        <h3>Mafé</h3>
                                        <div className="price">
                                            <p><span>12€</span></p>
                                        </div>
                                        <p className="fst-italic">
                                            <b><u>Accompagnement :</u> RIZ PARFUMÉ </b>

                                        </p>
                                        <ul>
                                            <li><i className="bi bi-check-circled"></i> Viande de (bœuf ou poulet)
                                            </li>
                                            <li><i className="bi bi-check-circled"></i> Patates douces, Pommes de
                                                terre
                                            </li>
                                            <li><i className="bi bi-check-circled"></i> Tomates, Oignons, carrottes
                                                , épices ,d'huile, sel et arômes
                                            </li>
                                            <li><i className="bi bi-check-circled"></i> Pâte d’arachide</li>
                                        </ul>
                                        <p>
                                            Le Mafé est une sauce très nutritive à base d'arachide originaire du
                                            Mali, surtout consommée en Afrique de l'ouest
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide carousel-item">
                                <div className="row event-item">
                                    <div className="col-lg-6">
                                        <img src="assets/img/platj/tiep.webp" className="img-fluid" alt=""/>
                                    </div>
                                    <div className="col-lg-6 pt-4 pt-lg-0 content">
                                        <h3>THIEP</h3>
                                        <div className="price">
                                            <p><span>12€</span></p>
                                        </div>
                                        <p className="fst-italic">
                                            "Le Thiep" le riz sénégalais au poisson , poulet, mouton , au boeuf
                                        </p>

                                        <ul>
                                            <li><i className="bi bi-check-circled"></i> Riz parfumé.</li>
                                            <li><i className="bi bi-check-circled"></i> Viande de bœuf ou poulet
                                            </li>
                                            <li><i className="bi bi-check-circled"></i> légumes, épices , huile, sel
                                                et arômes
                                            </li>
                                            <li><i className="bi bi-check-circled"></i> Tubercules, carottes.</li>
                                        </ul>
                                        <p>
                                            Le « tiep bou dièn » , « tiep bou yap » et le « tiep bou dièn », ce
                                            fameux ragoût à base de riz, légumes et poisson ,de viande de poulet, de
                                            mouton , ou de boeuf, est le plat national du Sénégal. C'est le plat le
                                            plus célèbre de la cuisine sénégalaise.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide carousel-item">
                                <div className="row event-item">
                                    <div className="col-lg-6">
                                        <img src="assets/img/platj/gombo.webp" className="img-fluid" alt=""/>
                                    </div>
                                    <div className="col-lg-6 pt-4 pt-lg-0 content">
                                        <h3>Custom Parties</h3>
                                        <div className="price">
                                            <p><span>12€</span></p>
                                        </div>
                                        <p className="fst-italic">
                                            <b><u>Accompagnement :</u> Semoule ou Manioc</b>
                                        </p>
                                        Ingrédients:
                                        , ,
                                        Options: Piment

                                        <ul>
                                            <li><i className="bi bi-check-circled"></i> Gombo frais.</li>
                                            <li><i className="bi bi-check-circled"></i> Viande, crevette, ou poisson
                                                fumée .
                                            </li>
                                            <li><i className="bi bi-check-circled"></i> huile, arachide, légumes ,
                                                épices et arômes d'Afrique.
                                            </li>
                                        </ul>
                                        <p>
                                            La sauce gombo est une sauce gluante à base de gombos frais ou secs,
                                            originaire d'Afrique. Elle elle possede plusieurs variantes et
                                            accompagne les plats de poisson ou de viande.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </section>


        )
    }
}
